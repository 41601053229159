import React, { ReactNode } from 'react';
import { Modal } from '@devstart/react-bootstrap';

interface RootProps {
  readonly children: ReactNode;
  readonly className?: string;
  readonly animation?: boolean;
  readonly show?: boolean;
  readonly keyboard?: boolean;
  readonly backdrop?: string | boolean;
  readonly onHide?: () => void;
  readonly onKeyDown?: () => void;
}

function Root({ children, ...rest }: RootProps): JSX.Element {
  return (
    <Modal {...rest} dialogClassName='custom-modal'>
      {children}
    </Modal>
  );
}

Root.displayName = 'Root';

export default Root;
